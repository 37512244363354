import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import VplMain from "../../components/video-production-live/vplMain"
import VplPortfolioLanding from "../../components/video-production-live/vplPortfolioLanding"
import VplWhyYouNeed from "../../components/video-production-live/vplWhyYouNeed"
import VplCompanyLogo from "../../components/video-production-live/vplCompanyLogo"
import AboutWork from "../../components/global/aboutWork"

import AevTestimonialHighlight from "../../components/animated-explainer-video/aevTestimonialHighlight"
import HomeTestimonials from "../../components/home/homeTestimonials"
import ProjectInMind from "../../components/global/projectInMind"
import DirectContact from "../../components/global/directContact"

import VplForm from "../../components/video-production-live/vplForm"
import whiteBoardVideoFeaturedImage from "../../../static/home//wowmakers.jpeg"

import "../../sass/pages/whiteboard-video-production.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={whiteBoardVideoFeaturedImage}
      title="Live Video Production Agency | WowMakers"
      description="We create corporate whiteboard explainer videos that meet your expectations. High-quality videos are delivered before deadlines."
    />
    <meta name="robots" content="noindex, nofollow" />
  </> 
)


const VideoProductionLive = () => {
  return (
    <Layout>
      <Seo
        bclass="whiteboard-video-production"
        bid="whiteboard-video-production"
      />
      <ScrollIndicationHeightZero />
      <VplMain />
      <VplPortfolioLanding />
      <VplWhyYouNeed />
      <VplCompanyLogo />
      <AboutWork />
      <AevTestimonialHighlight />
      <HomeTestimonials />
      <ProjectInMind title="Your story deserves the best. Partner with us to create a video that resonates." />
      <VplForm page="Live action videos new" />
      <DirectContact />
    </Layout>
  )
}

export default VideoProductionLive
