import React from "react"
import VplPortfolio from "./vplPortfolio"

import { Col, Container, Row } from "react-bootstrap"
import "../../sass/global/work-tab.scss"

const VplPortfolioLanding = () => {
  return (
    <section className="portfolio-works pb-0">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">Showcasing Excellence in Live Action Videos</h2>
            <p>Explore our dynamic videos, designed to captivate, engage, and elevate your brand's story. See our excellence in action.</p>
          </Col>
        </Row>
        <div className="wmtab-content active">
            <VplPortfolio />           
        </div>
      </Container>
    </section>
  )
}

export default VplPortfolioLanding
