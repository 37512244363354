import React from "react"
import MainLogo from "../global/mainLogo"
import ButtonIcon from "../icon/buttonIcon"
import { Col, Container, Row } from "react-bootstrap"
import "../../sass/global/main.scss"

const VplMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={6}>
            <div className="intro">
              <p className="service-label">LIVE ACTION VIDEO</p>
              <h1 className="title">
                Transform Your Brand Story with High-Impact Live Action Videos
              </h1>
              <p className="description">
                Professional video production that delivers compelling brand narratives.
              </p>
              <a
                href="/whiteboard-video-production/#CQL"
                className="btn wowaction-btn drift-chat-popup"
              >
                <ButtonIcon />
                <span>Have a project in mind? Chat with us</span>
              </a>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default VplMain
