import React from "react"
import ConveyMessageIcon from "../icon/conveyMessageIcon"
import ImproveIcon from "../icon/improveIcon"

import PricingIcon from "../icon/pricingIcon"
import AttentionIcon from "../icon/attentionIcon"
import ComplexIcon from "../icon/complexIcon"
import SimpleIcon from "../icon/simpleIcon"
import { Container, Row, Col } from "react-bootstrap"
import "../../sass/global/why-you-need.scss"

const VplWhyYouNeed = () => {
  return (
    <section className="why-you-need" style={{paddingBottom: "0"}}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              6 Reasons Why You Need A Live Action Video
            </h2>
            <p className="sub-desc">
              Learn why live action videos are crucial for storytelling, boosting conversions, building trust, and creating a memorable brand experience.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <PricingIcon />
              <h3 class="col-md-7">Enhanced Storytelling</h3>
              <p>
                Bring your brand's narrative to life with authentic, relatable stories.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <AttentionIcon />
              <h3 class="col-md-7">Increased Engagement</h3>
              <p>
                Capture and maintain viewer attention effectively than static images or text.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ComplexIcon />
              <h3 class="col-md-8">Fostering Credibility</h3>
              <p>
                Showcase real people and genuine emotions, building trust with audience.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ConveyMessageIcon />
              <h3 class="col-md-8">Boosted Conversions</h3>
              <p>
                Videos drive higher conversion rates, turning viewers into loyal customers.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <ImproveIcon />
              <h3 class="col-md-8">Improved Search Rankings</h3>
              <p>
                Enhance search rankings as videos increase time spent on your site.
              </p>
            </div>
          </Col>
          <Col md={4} sm={6}>
            <div className="steps text-center d-flex flex-column align-items-center">
              <SimpleIcon />
              <h3 class="col-md-7">Versatality and Reach</h3>
              <p>
                Use videos across platforms, maximizing your brand's impact.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default VplWhyYouNeed
