import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const VplCompanyLogo = () => {
    return(
        <section className="customers">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} className="text-center">
                        <h2 className="sub-title">Trusted by Leaders Worldwide</h2>
                        <p className="sub-desc col-lg-11 my-0 mx-auto">For 12 years, leading brands and organizations have chosen us as their partner. See why industry giants and innovative newcomers alike trust our expertise.</p>                        
                    </Col>
                    <Col md={12} className="mt-5">
                        <Row className="seven-cols align-items-center justify-content-center">
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/microsoft.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Microsoft"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/mercedes.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Mercedes Benz"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/CITI.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - CITI Bank"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Emaar.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Emaar"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Tencent.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Tencent"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/japan-gov.svg" class="img-fluid" width="231" height="150" alt="WowMakers Client - Japan Government"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Cognizant.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Cognizant"/>
                            </Col>                                                                                     
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/FIFA.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Fifa Worldcup Qatar 2022"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Reliance.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Reliance"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/AON.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client -AON"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Vi.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Vi"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Ooredoo.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Ooredoo"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/munucipality-of-qatar.svg" class="img-fluid" width="231" height="150" alt="WowMakers Client - MOC"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Govt of India.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Govt of India"/>
                            </Col>                                                                                     
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Qatar-Rail.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Qatar Rail"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Mannai.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Mannai"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Zoho.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Zoho"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Ogilvy.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Ogilvy"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Sanofi.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Sanofi"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Axis.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Axis Bank"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Federal.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Federal Bank"/>
                            </Col>                                                                                                             
                        
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Truecaller.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Truecaller"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/SIB.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - South Indian Bank"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/LTI.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - LTI"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Ceat.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Ceat"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/HDFC.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - HDFC Bank"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Colgate.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Colgate Palmolive"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Cult.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - CultFit"/>
                            </Col>                                                                                     
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/ICICI.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - ICICI Bank"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/GWC.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - GWC"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/BFC.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - BFC"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Grabert.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Grabert.svg"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/MPL.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - MPL"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Paytm.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Paytm.svg"/>
                            </Col>
                            <Col md={1} xs={3}>
                                <img src="/global/client-logo/Hike.svg" class="img-fluid" width="209" height="136" alt="WowMakers Client - Hike"/>
                            </Col>                                                                                                
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default VplCompanyLogo