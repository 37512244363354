import React from "react"
import { useStaticQuery, graphql} from "gatsby"
import VideoPortfolio from "../global/videoPortfolio"
import { Row, Col } from "react-bootstrap"

const VplPortfolio = () => {
    const dataaev = useStaticQuery(graphql` 
    {
      allMarkdownRemark(
        sort: {fields: frontmatter___sort, order: ASC}
        filter: {frontmatter: {category: {eq: "video-production-live"}, page: {eq: "animated-explainer-video"}, featured: {eq: true}}}
      ) {
        nodes {
          frontmatter {
            title
            videoid
            channel
            industry
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: AUTO, width: 410, height: 290, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }    
    `)

    const aevportfolios = dataaev.allMarkdownRemark.nodes

    return(
        <Col md={12} className="portfolio-content wrapper-main">
            <Row>
            {aevportfolios.map((aevportfolio) => (
                <Col lg={4} md={6}>
                    <VideoPortfolio  type={aevportfolio} />
                </Col>
            ))}
            </Row>
      </Col>
    )
}

export default VplPortfolio