import React from "react";
import { Container, Row, Col} from 'react-bootstrap'
import VideoWowForm from "../global/videoWowForm";

import '../../sass/global/form.scss'

const VplForm = ({ page }) => {
    return(
        <section className='form-main'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={8}>
                        <h2 className='text-center sub-title text-white'>Your Vision, Our Expertise</h2>
                        <p className='sub-desc text-white text-center'>Your vision deserves more than just a video—it deserves a creative partner. Our award-winning team blends storytelling and technology to produce impactful live action videos. Reach out now to embark on a creative journey with us.</p>
                        <VideoWowForm pname={page} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default VplForm